import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
export default function Secone(){
    return (
        <>
        
             <Container fluid className="__bg-thank">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="__thank">
                                <h2 className="text-center">
                                    THANK YOU
                                </h2>
                                <p className="text-center">
                                    Selamat! Kamu telah terdaftar dalam waiting list Commitment Mastery.<br/>
                                    Silakan Cek Email Kamu (Inbox,Spam Atau Promotion) untuk dapatkan BONUS <br/> 3 video series dan penawaran spesial.
                                </p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__image_logo">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/Ladingpage-plc/Logo-CM.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>
           

        </>
    )
}

