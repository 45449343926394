import React from "react"
import Layout from "../components/layouts/layout_blank"
import SEO from "../components/seo/seo"
import SectionOne from "./section/s_waitinglist/tq/s_tq"
import "../assets/css/style_wl.css"

const Tqam = () => (
  <Layout>
     <React.Fragment>
      <SEO title="Thank You Page Waiting List" />
          <SectionOne /> 
     </React.Fragment>
  </Layout>
)

export default Tqam
